/* istanbul ignore file: test forms */
import {FORMS_TEMPLATE_IDS} from '../constants';
import {FormError, FormValues, FormViewer, FormViewerHandle} from '@wix/form-viewer/widget';
import React from 'react';
import {useTranslation} from '@wix/yoshi-flow-editor';
import {useLocaleKeys} from '../../../locale-keys/LocaleKeys';
import {useControllerProps} from '../Widget/ControllerContext';
import {CheckoutSettingsModel} from '../../../domain/models/checkoutSettings/CheckoutSettings.model';
import {ContactModel} from '../../../domain/models/Contact.model';
import {FullAddressContactDetails} from '@wix/ambassador-ecom-v1-checkout/types';

export interface FormProps {
  formRef: React.MutableRefObject<FormViewerHandle | null>;
  formValues: FormValues;
  setFormValues: (value: ((prevState: FormValues) => FormValues) | FormValues) => void;
  formErrors: FormError[];
  setFormErrors: (value: ((prevState: FormError[]) => FormError[]) | FormError[]) => void;
}

export interface ContactFormProps extends FormProps {
  isBillingDetailsForm: boolean;
}

export const ContactForm = ({
  formRef,
  formValues,
  setFormValues,
  formErrors,
  setFormErrors,
  isBillingDetailsForm,
}: ContactFormProps) => {
  const {i18n} = useTranslation();
  const localeKeys = useLocaleKeys();
  const {
    checkoutSettingsStore: {checkoutSettings},
    formsStore: {shouldIncludeCompany},
  } = useControllerProps();

  return (
    <FormViewer
      i18n={i18n}
      ref={formRef}
      overrides={{
        first_name: {
          label: localeKeys.checkout.address_information.first_name_label(),
          required: true,
        },
        last_name: {label: localeKeys.checkout.address_information.last_name_label(), required: true},
        phone: {
          label: localeKeys.checkout.address_information.phone_number_label(),
          hidden: !checkoutSettings.phone.show,
          required: checkoutSettings.phone.mandatory,
        },
        company_name: {
          label: localeKeys.checkout.address_information.company_label(),
          hidden: !shouldIncludeCompany && !isBillingDetailsForm,
          required: checkoutSettings.companyName.mandatory,
        },
      }}
      formId={FORMS_TEMPLATE_IDS.CONTACT}
      values={formValues}
      errors={formErrors}
      onChange={setFormValues}
      onValidate={setFormErrors}
    />
  );
};

export const getContactFormInitialState = (
  checkoutSettings: CheckoutSettingsModel,
  shouldIncludeCompany: boolean,
  contact?: ContactModel
): FormValues => ({
  first_name: contact?.firstName ?? '',
  last_name: contact?.lastName ?? '',
  ...(checkoutSettings.phone.show ? {phone: contact?.phone ?? ''} : {}),
  ...(shouldIncludeCompany ? {company_name: contact?.company ?? ''} : {}),
});

export const getContactDetailsFromContactFormValues = (
  contactFormValues: FormValues,
  checkoutSettings: CheckoutSettingsModel,
  shouldIncludeCompany: boolean,
  contact?: ContactModel
): FullAddressContactDetails => ({
  ...(contactFormValues.first_name ? {firstName: contactFormValues.first_name as string} : {}),
  ...(contactFormValues.last_name ? {lastName: contactFormValues.last_name as string} : {}),
  ...(checkoutSettings.phone.show && contactFormValues.phone ? {phone: contactFormValues.phone as string} : {}),
  ...(shouldIncludeCompany && contactFormValues.company_name
    ? {company: contactFormValues.company_name as string}
    : {}),
  ...(contact?.vatId ? {vatId: contact.vatId} : {}),
});
