import React, {FC} from 'react';
import {useTranslation, WidgetProps} from '@wix/yoshi-flow-editor';
import {ControllerContext} from './ControllerContext';
import {ControllerProps} from '../../../types/app.types';
import {LocaleKeysProvider} from '../../../locale-keys/LocaleKeys';
import CheckoutApp from '../CheckoutApp/CheckoutApp';
import {withSlotsPlaceholders} from '@wix/widget-plugins-ooi';
import {withCheckoutThemeOverride} from '../WithCheckoutThemeOverride/WithCheckoutThemeOverride';
import {withStyles} from '@wix/native-components-infra';
import {FormViewerContext} from '@wix/form-viewer/widget';
import './index.css';
import {withExpressCheckoutButtons} from '@wix/ecom-platform-express-checkout-buttons';
import {withPaymentsApi} from '../WithPaymentsApi/WithPaymentsApi';

const Widget: FC<WidgetProps<ControllerProps>> = (controllerProps: WidgetProps<ControllerProps>) => {
  const {t} = useTranslation();

  return (
    <ControllerContext value={controllerProps}>
      <LocaleKeysProvider translateFn={t}>
        <FormViewerContext {...controllerProps}>
          <CheckoutApp host={controllerProps.host} ravenUserContextOverrides={{}} />
        </FormViewerContext>
      </LocaleKeysProvider>
    </ControllerContext>
  );
};

export default withPaymentsApi(
  withExpressCheckoutButtons(withCheckoutThemeOverride(withStyles(withSlotsPlaceholders(Widget), {strictMode: true})))
);
