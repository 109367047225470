/* istanbul ignore file: test forms */
import React, {useState} from 'react';
import {FormError, FormValues} from '@wix/form-viewer/widget';
import {ContactForm} from '../../../../Form/ContactForm';
import {FormHandle} from '@wix/form-viewer';
import {Text} from 'wix-ui-tpa';
import {classes} from '../PaymentStep.st.css';
import {useLocaleKeys} from '../../../../../../locale-keys/LocaleKeys';
import {useControllerProps} from '../../../../Widget/ControllerContext';
import {FormLoader} from '../../../../Form/FormLoader/FormLoader';

export enum BillingDetailsDataHook {
  root = 'BillingDetails.root',
  title = 'BillingDetails.title',
  contactForm = 'BillingDetails.contactForm',
  addressForm = 'BillingDetails.addressForm',
}

export interface BillingDetailsProps {
  setContactFormValues: React.Dispatch<React.SetStateAction<FormValues>>;
  contactFormValues: FormValues;
  contactFormViewer: React.RefObject<FormHandle>;
}

export const BillingDetails = ({setContactFormValues, contactFormValues, contactFormViewer}: BillingDetailsProps) => {
  const {
    formsStore: {areFormsLoaded},
  } = useControllerProps();

  const [contactFormErrors, setContactFormErrors] = useState<FormError[]>([]);
  const localeKeys = useLocaleKeys();

  return (
    <div data-hook={BillingDetailsDataHook.root} className={classes.root}>
      <Text data-hook={BillingDetailsDataHook.title}>{localeKeys.checkout.billing_information.title()}</Text>
      {!areFormsLoaded && <FormLoader />}
      {areFormsLoaded && (
        <div data-hook={BillingDetailsDataHook.contactForm} className={classes.billingForm}>
          <ContactForm
            formRef={contactFormViewer}
            formValues={contactFormValues}
            formErrors={contactFormErrors}
            setFormValues={setContactFormValues}
            setFormErrors={setContactFormErrors}
            isBillingDetailsForm={true}
          />
        </div>
      )}
    </div>
  );
};
