import React from 'react';
import {Text, TextPriority} from 'wix-ui-tpa';
import {classes} from './ContactAndAddressSummary.st.css';
import {useTranslation} from '@wix/yoshi-flow-editor';
import {ContactModel} from '../../../domain/models/Contact.model';
import {AddressModel} from '../../../domain/models/Address.model';
import {useLocaleKeys} from '../../../locale-keys/LocaleKeys';
import {getSubdivisionName} from '../../../domain/utils/localeDataset.util';
import {CustomFieldModel} from '../../../domain/models/CustomField.model';
import {useControllerProps} from '../Widget/ControllerContext';

export enum ContactAndAddressSummaryDataHook {
  root = 'ContactAndAddressSummary.root',
  title = 'ContactAndAddressSummary.title',
  fullName = 'ContactAndAddressSummary.fullName',
  email = 'ContactAndAddressSummary.email',
  phone = 'ContactAndAddressSummary.phone',
  company = 'ContactAndAddressSummary.company',
  addressRow = 'ContactAndAddressSummary.addressRow',
  customField = 'ContactAndAddressSummary.customField',
}

export const ContactAndAddressSummary = ({
  contact,
  address,
  email,
  customField,
}: {
  contact?: ContactModel;
  address?: AddressModel;
  email?: string;
  customField?: CustomFieldModel;
}) => {
  const localeKeys = useLocaleKeys();
  const {t} = useTranslation();
  const {
    navigationStore: {isMember},
    checkoutStore: {isMemberPickupFlow},
  } = useControllerProps();

  const shouldShowCompanyName = !(isMember && isMemberPickupFlow);

  const getAddressText = () => {
    const addressWithoutSubdivision = {
      addressLine1: address?.streetAddress?.name
        ? `${address?.streetAddress?.name} ${address?.streetAddress?.number ?? ''}`
        : address?.addressLine,
      addressLine2: address?.addressLine2,
      city: address?.city,
      zipCode: address?.postalCode,
      country: address?.countryFullname,
    };
    const subdivisionKey = getSubdivisionName(address?.country, address?.subdivision);

    if (subdivisionKey) {
      const subdivision = t(subdivisionKey);
      return localeKeys.checkout.shipping_details.delivery_address2({
        ...addressWithoutSubdivision,
        subdivision,
      });
    } else {
      return localeKeys.checkout.shipping_details.delivery_address2_without_subdivision(addressWithoutSubdivision);
    }
  };

  const getFullName = () => {
    return `${contact?.firstName ?? /* istanbul ignore next */ ''} ${
      contact?.lastName ?? /* istanbul ignore next */ ''
    }`;
  };

  return (
    <div data-hook={ContactAndAddressSummaryDataHook.root} className={classes.root}>
      <Text className={classes.content} priority={TextPriority.secondary}>
        <div data-hook={ContactAndAddressSummaryDataHook.fullName}>{getFullName()}</div>
        {shouldShowCompanyName && (
          <div data-hook={ContactAndAddressSummaryDataHook.company}>{contact?.company ?? ''}</div>
        )}
        {email && <div data-hook={ContactAndAddressSummaryDataHook.email}>{email}</div>}
        {address && <div data-hook={ContactAndAddressSummaryDataHook.addressRow}>{cleanAddress(getAddressText())}</div>}
        <div data-hook={ContactAndAddressSummaryDataHook.phone}>{contact?.phone ?? ''}</div>
        {customField && (
          <div data-hook={ContactAndAddressSummaryDataHook.customField}>
            {`${customField.title}: ${customField.value}`}
          </div>
        )}
      </Text>
    </div>
  );
};

export const cleanAddress = (addressLine: string): string => {
  return addressLine
    .split(',')
    .filter((word) => !!word.trim())
    .join(',');
};
