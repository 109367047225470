import {CustomerDetailsOpen} from './Components/CustomerDetailsOpen';
import {CustomerDetailsCollapsed} from './Components/CustomerDetailsCollapsed';
import React, {useEffect} from 'react';
import {Step} from '../../StepsManager/Components/Step';
import {StepImplementationProps, StepId} from '../../types';
import {useLocaleKeys} from '../../../../../locale-keys/LocaleKeys';
import {useControllerProps} from '../../../Widget/ControllerContext';

export enum CustomerDetailsStepDataHook {
  root = 'CustomerDetailsStep.root',
  collapsed = 'CustomerDetailsStep.collapsed',
  open = 'CustomerDetailsStep.open',
  empty = 'CustomerDetailsStep.empty',
  continueButton = 'CustomerDetailsStep.continue',
}

export const CustomerDetailsStep = ({index}: StepImplementationProps) => {
  const localeKeys = useLocaleKeys();
  const {
    navigationStore: {isMember},
    stepsManagerStore: {goToNextStep},
    checkoutStore: {isMissingCustomField},
  } = useControllerProps();

  useEffect(() => {
    if (isMember && !isMissingCustomField) {
      goToNextStep();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Step
      index={index!}
      title={localeKeys.checkout.customer_details()}
      dataHook={CustomerDetailsStepDataHook.root}
      openComponent={CustomerDetailsOpen}
      collapsedComponent={CustomerDetailsCollapsed}
    />
  );
};

CustomerDetailsStep.id = StepId.customerDetails;
